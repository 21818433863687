.section {
  background-repeat: no-repeat;
  padding-top: calc(var(--section-padding) / 2);
  padding-bottom: calc(var(--section-padding) / 2);
  @include media-breakpoint-up(md) {
    padding-top: var(--section-padding);
    padding-bottom: var(--section-padding);
  }
  @include media-breakpoint-up(lg) {
    padding-top: var(--section-padding);
    padding-bottom: var(--section-padding);
  }
  .section-heading {
    text-align: center;
    margin-bottom: var(--section-padding);
  }
  .section-footer {
    margin-top: var(--section-padding);
    text-align: center;
  }
  &.section-base-bg {
    background-color: var(--color-base-bg);
  }
  &.section-base-bg-2 {
    background-color: var(--color-base-bg-2);
  }
  &.section-base-bg-3 {
    background-color: var(--color-base-bg-3);
  }
}
