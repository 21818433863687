.faqs {
  .faq {
    border: 1px solid var(--color-base-bg-2);
    h3 {
      margin-bottom: 10px;
    }
    p {
      line-height: 1.4;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
